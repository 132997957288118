import Decimal from '@st/decimal'
import type { Freebet } from '../types'
import { useBonusRolling } from './useBonusRolling'

export function useFreebet(freebet: Ref<Freebet>) {
  const status = computed(() => {
    if (!freebet.value.betId) return 'new'
    if (freebet.value.betId && !freebet.value.payoff) return 'process'
    if (freebet.value.betId && freebet.value.rollingStartedAt) return 'rolling'
    return ''
  })

  const { format: formatMultiplier } = useNumberFormatter()
  const { format: formatCrypto } = useCryptoFormatter({
    currency: computed(() => freebet.value.currencyCode),
  })
  const { format: formatCryptoDefault } = useCryptoFormatter()
  const { t, locale } = useI18n()

  const listFormatter = new Intl.ListFormat(locale.value, {
    style: 'long',
    type: 'conjunction',
  })

  const freebetAmount = computed(() => formatCrypto(freebet.value.amount ?? 0))

  const wager = computed(() =>
    formatMultiplier(
      freebet.value.freebetRollingSettings.rollingMultiplier ?? 0,
    ),
  )

  const currentRewardAmount = computed(() =>
    formatCrypto(freebet.value.payoff ?? 0),
  )

  const maxRollingAmount = computed(() =>
    new Decimal(freebet.value.payoff ?? '0')
      .times(freebet.value.freebetRollingSettings.rollingMultiplier)
      .toString(),
  )

  const maxRollingAmountCurrency = computed(() =>
    formatCrypto(maxRollingAmount.value),
  )

  const minRollingAmountCurrency = computed(() => formatCrypto(0))

  const minMaxRollingText = computed(
    () =>
      `${formatCrypto(freebet.value.currentRollingAmount)}/${formatCrypto(
        maxRollingAmount.value,
      )}`,
  )

  const minMaxRollingTextWithoutCurrency = computed(
    () =>
      `${formatCryptoDefault(
        freebet.value.currentRollingAmount,
      )}/${formatCryptoDefault(maxRollingAmount.value)}`,
  )

  const currentProgressAmount = computed(() =>
    formatCrypto(freebet.value.currentRollingAmount),
  )

  const progress = computed(() => {
    if (status.value !== 'rolling') return ''

    const result = new Decimal(freebet.value.currentRollingAmount)
      .div(maxRollingAmount.value)
      .mul(100)
      .toFixed(2)

    return Decimal.min(result, 100).toString()
  })

  const amountLeft = computed(() => {
    if (!freebet.value.payoff) return 0
    return new Decimal(freebet.value.payoff)
      .mul(freebet.value.freebetRollingSettings.rollingMultiplier)
      .minus(freebet.value.currentRollingAmount)
      .toString()
  })

  const amountLeftCrypto = computed(() => formatCrypto(amountLeft.value))

  const currentExpiredDate = computed(() => freebet.value.expiredAt)

  const timerTitle = computed(() => t('bonuses.timerTitle'))

  const betTypeMap = {
    ordinary: t('bonuses.ordinary'),
    express: t('bonuses.express'),
  }

  const allowedBetTypes = computed(() =>
    freebet.value.freebetSettings.allowedBetTypes.map(
      (item) => betTypeMap[item],
    ),
  )

  const minBetRate = computed(() => freebet.value.freebetSettings.minBetRate)
  const formattedMinBetRate = computed(() =>
    formatMultiplier(minBetRate.value ?? 0),
  )
  const maxBetRate = computed(() => freebet.value.freebetSettings.maxBetRate)
  const formattedMaxBetRate = computed(() =>
    formatMultiplier(maxBetRate.value ?? 0),
  )

  const minMaxRateMessage = computed(() => {
    if (minBetRate.value && !maxBetRate.value) {
      return t('bonuses.minRate', { minRate: minBetRate.value })
    }
    if (!minBetRate.value && maxBetRate.value) {
      return t('bonuses.maxRate', { maxRate: maxBetRate.value })
    }

    if (minBetRate.value && maxBetRate.value) {
      return t('bonuses.minMaxRate', {
        minRate: minBetRate.value,
        maxRate: maxBetRate.value,
      })
    }
    return ''
  })

  const formattedAllowedBetTypes = computed(() =>
    listFormatter.format(allowedBetTypes.value),
  )

  const list = computed(() => [
    {
      label: t('bonuses.bettingOdds'),
      value: minMaxRateMessage.value,
    },
    {
      label: t('bonuses.bettingCondition'),
      value: t('bonuses.wager', {
        multiplier: freebet.value.freebetRollingSettings.rollingMultiplier,
      }),
      disabled: freebet.value.freebetRollingSettings.rollingMultiplier === '0',
    },
  ])

  const filteredList = computed(() =>
    list.value.filter((item) => !item.disabled),
  )

  const subtitle = computed(() => {
    switch (status.value) {
      case 'new':
        return t('bonuses.betDone')
      case 'process':
        return t('bonuses.betWon')
      default:
        return t('bonuses.shouldMakeBet')
    }
  })

  const {
    isRollingStatusInProgress,
    isRollingStatusNewOrPaused,
    setCurrentRolling,
    rollingStatus,
  } = useBonusRolling(computed(() => freebet.value.rolling))

  const progressBarColor = computed(() =>
    isRollingStatusNewOrPaused.value ? '#4E4E60' : undefined,
  )

  return {
    currentRewardAmount,
    minMaxRollingText,
    minMaxRollingTextWithoutCurrency,
    wager,
    status,
    progress,
    maxRollingAmount,
    amountLeft,
    currentExpiredDate,
    timerTitle,
    filteredList,
    subtitle,
    currentProgressAmount,
    amountLeftCrypto,
    maxRollingAmountCurrency,
    minRollingAmountCurrency,
    freebetAmount,
    minBetRate,
    maxBetRate,
    formattedAllowedBetTypes,
    formattedMinBetRate,
    formattedMaxBetRate,
    rollingStatus,
    progressBarColor,
    isRollingStatusNewOrPaused,
    setCurrentRolling,
    isRollingStatusInProgress,
  }
}
